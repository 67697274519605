import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import CommodityDetail from "../views/CommodityDetail.vue";
import UpdateCommodity from "../views/UpdateCommodity.vue";
import LoginView from "@/views/LoginView.vue";
import ProfileView from "@/views/ProfileView.vue";


const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/commodity/:category",
    name: "commodity-detail",
    component: CommodityDetail,
  },
  {
    path: "/update",
    name: "update",
    component: UpdateCommodity,
    meta: { requiresAuth: true }, // Protect this route
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
   
  },
  {
    path: "/profile",
    name: "profile",
    component: ProfileView,
   
  },

  
];

// Create the router instance
const router = createRouter({
  history: createWebHistory(),
  routes,
});



export default router;
