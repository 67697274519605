import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import vue3GoogleLogin from 'vue3-google-login'

const app = createApp(App)

app.use(vue3GoogleLogin, {
  clientId: '815203163055-9u6u7gdvamgml9lo2oltf8m6hcf4u5i6.apps.googleusercontent.com'
})

app.use(router)

app.mount('#app')

