<template>
    <div class="login">
      <GoogleLogin :callback="callback"  class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 font-bold rounded"
      prompt auto-login/>
    </div>
  </template>


<script setup>
import { useRouter } from 'vue-router';
import { GoogleLogin } from 'vue3-google-login';
import { decodeCredential } from 'vue3-google-login';

const router = useRouter()

const callback = (response) => {

  console.log("Google login responses", response)
  let profile = decodeCredential(response.credential)


  localStorage.setItem('user', JSON.stringify(profile))

  router.push('/profile')

}


</script>

<style scoped>
.login button {
  background-color: #4285f4;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
</style>