<template>
  <div id="app">
    <nav class="navbar">
      <div class="navbar-logo">
        <router-link to="/" class="home-link">
          <span>POTA AI</span>
        </router-link>
      </div>
      <ul class="navbar-links">
        <li><a href="#" @click.prevent="toggleModal">Calendar</a></li>
        <li><a href="#" @click.prevent="toggleModal">News</a></li>
        <li><a href="#" @click.prevent="toggleModal">Markets</a></li>
        <li><a href="#" @click.prevent="toggleModal">Indicators</a></li>
        <li><a href="#" @click.prevent="toggleModal">Countries</a></li>
        <li><a href="#" @click.prevent="toggleModal">Search</a></li>
      </ul>
      <div class="navbar-icons">
        <button class="notification-button" @click.prevent="toggleModal">🔔</button>
       
        <div v-if="user">
      <!-- Jika user ada, tampilkan tombol Logout -->
     
      <button @click="logout" class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
        Logout
      </button>
    </div>
    <div v-else>
      <!-- Jika user tidak ada, tampilkan tombol Google Login -->
      <GoogleLogin 
        :callback="callback" 
        class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        prompt 
        auto-login 
      />
    </div>
    
       
        
      
      </div>
    </nav>
    <div class="main-container">
      <div v-if="showModal" class="modal">
        <div class="modal-content">
          <span class="close-button" @click="toggleModal">&times;</span>
          <h2>Under Development</h2>
          <p>This section is currently under development. Thank you for your patience.</p>
        </div>
      </div>

      <div style="margin-top: 20px;">
        <router-view />
      </div>

      
    </div>
    <footer class="footer">
      @2025 Research Led by Vavib Technology
    </footer>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { GoogleLogin, decodeCredential } from 'vue3-google-login';

const router = useRouter();
const user = ref(null);

// Callback untuk login Google
const callback = async (response) => {
  console.log("Google login responses", response);
  let profile = decodeCredential(response.credential);
  localStorage.setItem('user', JSON.stringify(profile));
  try {
    const res = await fetch('https://api.pota.ai/users', { // Ganti dengan URL backend Anda
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: profile.name,
        email: profile.email,
        profile: profile, // Atau data lain yang ingin Anda simpan
      }),
    });

    if (!res.ok) {
      throw new Error('Failed to save user data');
    }

    const data = await res.json();
    console.log(data); // Tampilkan respons dari server

    // Anda bisa menyimpan ID pengguna atau data lain jika perlu
    // localStorage.setItem('userId', data.id); // Contoh menyimpan ID pengguna

    location.reload(); 
  } catch (error) {
    console.error("Error saving user data:", error);
  }
 
};

// Fungsi untuk mendapatkan informasi pengguna
const getUserInfo = () => {
  const storedUser = localStorage.getItem('user');
  if (storedUser) {
    user.value = JSON.parse(storedUser);
  } else {
    router.push('/');
  }
};

// Mengambil informasi pengguna saat komponen dimuat
onMounted(() => {
  getUserInfo();
});

// Fungsi untuk logout
const logout = () => {
  localStorage.removeItem('user');
  location.reload(); 
};

// Tidak perlu return di sini, semua variabel dan fungsi sudah tersedia di template
</script>


<style>
.main-container {
  padding: 0 10%; /* Adjust padding as needed for left and right spaces */
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  width: 300px;
  text-align: center;
}

.close-button {
  float: right;
  font-size: 25px;
  cursor: pointer;
}
.footer {
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 10px 0;
  font-size: 14px;
  margin-top: 20px;
  bottom: 0;
  width: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav ul {
  list-style: none;
  padding: 0;
}

nav ul li {
  display: inline;
  margin-right: 20px;
}

/* Navbar Styles */
.navbar {
  
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  font-family: Arial, sans-serif;
}

.navbar-logo .home-link {
  text-decoration: none; /* Removes underline from the link */
  color: #fff; /* Sets the color to white, matching your navbar text color */
}

.navbar-logo .home-link span {
  cursor: pointer; /* Changes cursor to pointer to indicate it's clickable */
}

.navbar-logo .home-link:hover {
  text-decoration: none; /* Optional: Ensures no underline on hover */
}

.navbar-logo span {
  font-size: 18px;
  font-weight: bold; /* Makes the text bold */
}

.navbar-links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navbar-links li {
  margin-right: 15px;
}

.navbar-links a {
  text-decoration: none;
  color: #fff;
  font-size: 14px;
}

.navbar-links a:hover {
  text-decoration: underline;
}

.navbar-icons {
  display: flex;
  align-items: center;
}

.navbar-icons button {
  background: none;
  border: none;
  color: #fff;
  font-size: 18px;
  margin-left: 10px;
  cursor: pointer;
}
</style>
